.video-wrapper {
    position: relative;
    padding-bottom: 75%; /* 4:3 aspect ratio */
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    padding-top: 0; // height of controls
    height: 0;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}