.menu-component {
    position: absolute;
    padding: 15px 25px;
    width: 100%;
    top: 0;
    z-index: 1;

    .brand-logo {
        position: relative;
        width: 110px;
        z-index: 1000;
        font-size: 25px;
        font-weight: bold;
        color: rgb(0,0,0);
        transition: 0.5s;
        display: inline-block;
        text-transform: uppercase;
    }

    .sidenav {
        width: 100vw;
        background-color: rgba(255, 0, 0, 0.8);
        
        .item-close {
            position: absolute;
            right: 25px;
            top: 15px;
            .material-icons {
                font-size: 40px;
                cursor: pointer;
                color: rgb(255, 255, 255);
            }
        }
        .content-menu {
            width: 95%;
            text-align: center;
            margin: 40vh auto 0;
            text-transform: uppercase;
            transition: all 1s;

            a, span {
                height: auto;
                display: block;
                color: #fff;
                font-size: 2rem;
                cursor: pointer;
                letter-spacing: 2px;
                margin-bottom: 5px;
                transition: all 1s;
                font-family: 'GalanoGrotesqueBold';
                
                &:hover {
                    background: none;
                }
            }

            .content-submenu {
                max-height: 0;
                overflow: hidden;
                -webkit-transition: max-height 1s;
                -moz-transition: max-height 1s;
                -ms-transition: max-height 1s;
                -o-transition: max-height 1s;
                transition: max-height 1s;
                
                &.active {
                    max-height: 99rem;
                }
            }

            &.active-submenu {
                margin-top: 8vh;

                .link-menu {
                    a, span {
                        font-size: 1.2rem;
                    }
                }
            }
        }
    }

    .sidenav-trigger {
        transition: 0.5s;
        .material-icons {
            font-size: 40px;
            color: rgb(0, 0, 0);
        }
    }

    @media (min-width: 768px) {
        .brand-logo {
            width: auto;
            max-width: 243px;
        }
        .sidenav {
            .content-menu {
                width: 50%;

                a, span {
                    margin-bottom: 25px;
                    letter-spacing: 8px;
                    font-size: 3.2rem;
                }

                &.active-submenu {
                    margin-top: 15vh;

                    .link-menu {
                        a, span {
                            font-size: 1.5rem;
                        }
                    }
                }
            }
        }
    }
}

.sidenav-overlay {
    background: none;
}

.sidenav-overlay {
    display: none !important;
}

@media screen and (min-width: 900px) and (max-height: 700px) {
    .menu-component .sidenav .content-menu a, 
    .menu-component .sidenav .content-menu span {
        margin-bottom: 15px;
    }
    .menu-component .sidenav .content-menu.active-submenu {
        margin-top: 10vh;
    }
}