.equipo-background{
    background: url('/media/equipo/bg-img.jpg?v=1') no-repeat; 
    background-attachment: fixed;
    background-size: cover; /* Resize the background image to cover the entire container */
}

.equipo {
    .valign-wrapper {
        height: 60vh;

        .equipo-about{
            font-size: 1.7em;
            text-align: justify;
            font-family: 'GalanoGrotesqueSemiBold';
            letter-spacing: 1.5px;
        }

        @media (min-width: 1000px) and (min-height: 680px) and (max-height: 800px) {
            height: auto;
        }
    }

    @media screen and (max-width: 600px) {
        .valign-wrapper {
            .equipo-about{
                font-size: 1.2em;
            }
        }
    }
}