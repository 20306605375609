.container-estrategias{
    h1 {
        margin-top: 0;
        font-family: 'GalanoGrotesqueBold';
        font-size: 3em;
    }
    
    p {
        font-family: 'GalanoGrotesque';
        font-size: 1em;
    }

    .menu-estrategias{
        margin-top: 10px;
        margin-bottom: 10px;

        li{
            font-weight: bold;
            font-family: 'GalanoGrotesque';
            font-size: 1em;
        }
    }
}