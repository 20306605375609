.container-btnlanguage {
    position: absolute;
    display: none;
    bottom: 0;
    a {
        color: #000;
    }
}

@media only screen and (max-width: 600px) {
    .container-btnlanguage {
        position: relative;
        display:  block;
        bottom: auto;
    }
    
}