body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

*:focus {
    outline: 0;
    outline: none;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.no-js #loader { display: none;  }
.js #loader { display: block; position: absolute; left: 100px; top: 0; }
.se-pre-con {
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	z-index: 9999;
	background: url(/media/Preloader_11.gif) center no-repeat #fff;
}

@font-face {
    font-family: 'GalanoGrotesqueBold';
    src: local('GalanoGrotesqueBold'), url(./fonts/Galano_Grotesque_Bold.otf) format('truetype');
}

@font-face {
    font-family: 'GalanoGrotesqueExtraBold';
    src: local('GalanoGrotesqueExtraBold'), url(./fonts/Galano_Grotesque_Extra_Bold.otf) format('truetype');
}

@font-face {
    font-family: 'GalanoGrotesque';
    src: local('GalanoGrotesque'), url(./fonts/Galano_Grotesque.otf) format('truetype');
}

@font-face {
    font-family: 'GalanoGrotesqueMedium';
    src: local('GalanoGrotesqueMedium'), url(./fonts/Galano_Grotesque_Medium.otf) format('truetype');
}

@font-face {
    font-family: 'GalanoGrotesqueSemiBold';
    src: local('GalanoGrotesqueSemiBold'), url(./fonts/Galano_Grotesque_Semi_Bold.otf) format('truetype');
}

@font-face {
    font-family: 'MyriadProRegular';
    src: local('MyriadProRegular'), url(./fonts/MyriadPro-Regular.otf) format('truetype');
}

@media screen and (max-width: 600px) {
    body {
        /* display: none !important;*/ 
    }
}