* {
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	margin: 0;
}

body {
    overflow: hidden;
}

.hide-opacity {
  	opacity: 0;
}

.page-internal {
  	padding: 70px 20px;
}

.container {
    opacity: 0;
    transition: all 1s;
    margin-top: 100px;
}

.container-internal{
	overflow: hidden;
}

.internal {
	position: relative;
	width: 200%;
	left: 0;
	transition: 1s;

    .internal-main,
    .internal-gallery {
        position: relative;
		width: 50%;
		display: inline-block;
		vertical-align: top;
	}
}

.responsive-img{
    width: 100%;
    height: auto;
}


.page-title{
    font-family: 'GalanoGrotesqueBold';
    font-size: 3em;
}

//Pages Link Subtitle Container
.page-subtitle-container{
    position: absolute;
    bottom: 30px;
    left: 0;
    right: 0;
    margin: auto;
    padding: 35px;
    letter-spacing: 1.5px;
    text-align: center;

    // Pages Links Subtitle Style
    .page-subtitle{
        font-family: 'GalanoGrotesqueBold';
        text-decoration: underline;
        font-size: 1.3em;
        color: #000;
    }
}

@media screen and (max-width: 600px) {
    body{
        overflow-y: scroll;
    }
    .page-subtitle{
        font-size: 1.9em;
    }

    .page-title{
        font-size: 2em;
    }
}

@media screen and (max-width: 400px) {
    .container.container-deportivo,
    .container.container-entretenimientos,
    .container.container-medios,
    .container.container-eventos {
        margin-top: 60px;
    }
}

@media screen and (min-width: 900px) and (max-height: 700px) {
    .container.container-deportivo,
    .container.container-entretenimientos,
    .container.container-medios,
    .container.container-eventos {
        margin-top: 60px;
    }
}