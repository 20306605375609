
.contacto {
    .input-field{
        margin-bottom: 2rem;
        
        label{
            color: #000;
            font-size: 0.9em;
            font-weight: bold;
        }
    }
}

.contacto-title{
    font-family: 'GalanoGrotesqueExtraBold';
    font-size: 7.5em;
}

.contacto-btn-submitter{
    text-align: right;

    .contacto-link-enviar{
        font-weight: bold;
        padding: 0 3px;
    }
}

.contacto-paragraph{
    font-family: 'GalanoGrotesque';
    font-size: 0.9em;
    strong {
        font-family: 'GalanoGrotesqueBold';
        letter-spacing: 1px;
    }
}

.custom-ta{
    min-height: 6rem !important;
}

.lang-selector{
    cursor: pointer;
}

@media screen and (max-width: 600px) {
    .contacto-title{
        font-size: 3em;
    }

    .contacto-btn-submitter{
        text-align: center;
        float: none !important;

        .contacto-link-enviar{
            font-size: 1.4em;
        }
    }
    
}