.btn-contacto-component {
    a {
        color: #000;
        text-transform: uppercase;
        text-decoration: underline;
        font-family: 'GalanoGrotesqueBold';
        font-size: 1.2em;
        letter-spacing: 2px;
    }

    @media only screen and (max-width: 600px) {
        position: relative;
        bottom: auto;

        .center-btn{
            text-align: center;;
        }
    }
}