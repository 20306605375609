
.home {
    .valign-wrapper {
        height: 60vh;

        .home-about{
            font-size: 1.7em;
            text-align: justify;
            font-family: 'GalanoGrotesqueBold';
            letter-spacing: 1.5px;
        }
    }

    @media screen and (max-width: 600px) {
        .valign-wrapper {
            .home-about{
                font-size: 1.2em;
            }
        }
    }

    canvas {
        position: absolute;
        left: 0;
        top: 0;
    }

    .content-animation {
        position:absolute;
        width: 600px;
        height: 710px;

        left:0; right:0;
        top:0; bottom:0;
        margin:auto;

        .circle {
            top: 0;
            width: 600px;
            height: 600px;
            background: url(./../../media/esfera-stadium.png);
            background-size: cover;
    
            /*animation*/
            animation-name: floating-circle;
            animation-duration: 4s;
            animation-iteration-count: infinite;
            animation-timing-function: ease-in-out;

            /* ----- NODOS ----- */
            .content-nodos {
                position:absolute;
                width: 100%;
                height: 100%;
                top: 0;


                .nodos {
                    position: relative;
                    width: 100%;
                    height: 100%;

                    .nodo {
                        position: absolute;
                        width: 10px;
                        height: 10px;
                        background: red;
                        border-radius: 30px;
                        cursor: pointer;
                        transition: transform .5s;
                        z-index: 2;
                        transform: scale(1.5);

                        &.first-animation {
                            transform: scale( 3 );
                        }

                        &:hover {
                            transform: scale(4);
                        }

                        &[data-number="1"] {
                            left: -0.7%; top: 48%;
                        }
                    
                        &[data-number="2"] {
                            left: 45%; top: 105%;
                        }
                    
                        &[data-number="3"] {
                            left: 95%; top: 70%;
                        }
                    
                        &[data-number="4"] {
                            left: 86%; top: 15%;
                        }
                    
                        &[data-number="5"] {
                            left: 55%; top: -0.3%;
                        }
                    
                        &[data-number="6"] {
                            left: 6%; top: 6%;
                        }
                    }
                }
            }
        }

        .circle-shadow {
            position: relative;
            width: 70%;
            top: 60px;
            height: 20px;
            background: rgba(153, 153, 153, 0.48);
            filter: blur(3px);
            opacity: 0.5;
            border-radius: 100%;
            margin: 0 auto;
    
            /*animation*/
            animation-name: floating-circle-shadow;
            animation-duration: 4s;
            animation-iteration-count: infinite;
            animation-timing-function: ease-in-out;
        }
    }

    .steve-jobs {
        position: fixed;
        bottom: 80px;
        left: 130px;
        font-family: 'MyriadProRegular';
        font-size: 1.5em;
        transition: all 2s;
        opacity: 0;

        &.first-animation {
            opacity: 1;
        }

        hr {
            width: 35px;
            display: inline-block;
            border: 0;
            border-bottom: 1px solid;
        }

        @media screen and (max-width: 600px) {
            bottom: 10px;
            left: 10px;
            font-size: 1.1em;
        }
    }
    
    @keyframes floating-circle {
        0% { transform: translateY(  0px ) }
        50% { transform: translateY( 20px ) }
        100% { transform: translateY(  0px ) }
    }

    @keyframes floating-circle-shadow {
        0% { width: 70%; background: rgba(153, 153, 153, 0.48); }
        50% { width: 60%; background: rgba(45, 45, 45, 0.7); }
        100% { width: 70%; background: rgba(153, 153, 153, 0.48); }
    }

    .content-nodos .nodo:hover + div {
        opacity: 1;
    }



    /* ----- LABELS ----- */
    .content-nodos .label {
        position: absolute;
        opacity: 0;
        transition: all .5s;
        white-space: nowrap;
        font-size: 14px;
        font-family: 'GalanoGrotesqueMedium';
        color: red;
    }

    .content-nodos .label[data-number="1"] {
        top: 52%; left: -12%;
    }

    .content-nodos .label[data-number="2"] {
        top: 102%; right: 59%;
    }

    .content-nodos .label[data-number="3"] {
        top: 64%; left: 99%;
    }

    .content-nodos .label[data-number="4"] {
        top: 16%; left: 91%;
    }

    .content-nodos .label[data-number="5"] {
        top: -5%; right: 48%;
    }

    .content-nodos .label[data-number="6"] {
        top: 9%; left: -5%;
    }



    /* ----- LINES ----- */
    .content-nodos .lines .line {
        position: absolute;
        z-index: 1;
        border-top: 1px solid #ff000073;
    }

    .content-nodos .lines .line[data-number="1"] {
        width: 50%;
        transform: rotate(173deg);
        top: 3.5%;
        left: 6.3%;
    }

    .content-nodos .lines .line[data-number="2"] {
        width: 74%;
        transform: rotate(51deg);
        top: 77.4%;
        left: -14%;
    }

    .content-nodos .lines .line[data-number="3"] {
        width: 62%;
        transform: rotate(145deg);
        top: 87.9%;
        left: 40%;
    }

    .content-nodos .lines .line[data-number="4"] {
        width: 56%;
        transform: rotate(81deg);
        top: 44%;
        left: 63.3%;
    }

    .content-nodos .lines .line[data-number="5"] {
        width: 35%;
        transform: rotate(27deg);
        top: 8%;
        left: 53%;
    }

    .content-nodos .lines .line[data-number="6"] {
        width: 44%;
        transform: rotate(99deg);
        top: 28%;
        left: -18.5%;
    }

    .animate-fade {
        transition: opacity 600ms;
        
        &.hide-o {
            opacity: 0;
        }
    }

    @media only screen and (max-width: 600px), screen and (max-height: 850px) {
        .content-animation {
            width: 350px;
            height: 415px;

            .circle {
                width: 350px;
                height: 350px;

                .content-nodos {
                    .nodos {
                        .nodo {
                            transform: scale(1.3);

                            &[data-number="1"] { left: -1.2% }
                            &[data-number="2"] { top: 103% }
                            &[data-number="3"] { left: 94% }
                            &[data-number="6"] { left: 7%; top: 7% }
                        }
                    }
                }

                .label {
                    &[data-number="1"] { top: 38%; left: -20%; }
                    &[data-number="2"] { right: 60%; }
                    &[data-number="3"] { top: 77%; left: 92%; }
                    &[data-number="4"] { top: 6%; left: 90%; }
                    &[data-number="5"] { top: -7%; right: 51%; }
                    &[data-number="6"] { top: 12%; left: -11%; }
                }
            }

            .circle-shadow {
                top: 35px;
                height: 12px;
            }
        }

        .home .content-nodos .lines .line[data-number="1"] { transform: rotate(171deg); top: 4%; left: 8%; }
        .home .content-nodos .lines .line[data-number="2"] { transform: rotate(49deg); }
        .home .content-nodos .lines .line[data-number="3"] { width: 60%; left: 41%; transform: rotate(146deg); }
        .home .content-nodos .lines .line[data-number="6"] { transform: rotate(101deg); left: -16.5%; width: 42%; }
    }

    @media only screen and (max-width: 500px) {
        .content-animation {
            width: 260px;
            height: 310px;
            
            .circle {
                width: 260px;
                height: 260px;

                .content-nodos {
                    .nodos {
                        .nodo {
                            transform: scale(1.3);

                            &.first-animation { transform: scale(1.3); }

                            &[data-number="1"] { left: -1.5% }
                            &[data-number="3"] { left: 94% }
                            &[data-number="5"] { top: -0.5% }
                        }
                    }
                }

                .label {
                    opacity: 1;
                    font-size: 9px;

                    &[data-number="1"] { top: 51%; left: -17%; }
                    &[data-number="2"] { top: 101%; right: 57%; }
                    &[data-number="3"] { top: 75%; left: 95%; }
                    &[data-number="4"] { top: 8%; left: 84%; }
                    &[data-number="5"] { top: -6.5%; right: 47%; }
                    &[data-number="6"] { top: 9%; left: -8%; }
                }
            }

            .circle-shadow {
                top: 45px;
            }
        }
    }

    @media only screen and (max-width: 415px) {
        canvas { width: 415px !important; }
    }

    @media only screen and (max-width: 375px) {
        canvas { width: 375px !important; }
    }
}