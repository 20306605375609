.gallery-component {
    padding-top: 10px;

    h5 {
        font-family: 'GalanoGrotesqueBold';
        letter-spacing: 1px;
        padding-bottom: 20px;
    }

    .gallery-body {

        .gallery-principal-image {
            padding: 0;
            position: relative;
            min-height: 240px;

            .play-video {
                position: absolute;
                width: 60px;
                height: 60px;
                left: 50%;
                margin-left: -30px;
                top: 50%;
                margin-top: -30px;
                background-image: url(../../media/play.png);
                background-position: center;
                background-size: cover;
            }
        }

        .gallery-info {
            .name {
                font-size: 16px;
                letter-spacing: 1px;
                font-family: 'GalanoGrotesqueBold';
                margin-bottom: 10px;
            }

            .anio, .description {
                font-family: 'GalanoGrotesqueMedium';
            }

            .anio {
                font-size: 13px;
            }

            .description {
                font-size: 12px;
            }
        }
    }

    .box-preview {
        position: relative;
        height: 200px;
        border: 1px solid #fff;
        cursor: pointer;

        &[data-video='1'] {
            &:before {
                content: "";
                position: absolute;
                width: 60px;
                height: 60px;
                left: 50%;
                margin-left: -30px;
                top: 50%;
                margin-top: -30px;
                background-image: url('../../media/play_red.png?v=1');
                background-position: center;
                background-size: cover;
                z-index: 1;
            }
        }
        
        .box-preview-bg {
            position: relative;
            height: 200px;
            background-position: center;
            background-size: cover;
            transition: all 1s;

            &.inactive {
                filter: grayscale(1);
            }
            &:hover {
                filter: grayscale(0) !important;
            }
        }
    }

    .modal {
        background-color: rgba(250, 250, 250, 0);

        .modal-content {
            padding: 0;
        }

        .modal-close {
            position: absolute;
            right: 20px;
            top: 20px;
            width: 20px;
            height: 20px;
            opacity: 0.9;

            &:hover {
                opacity: 1;
            }

            &:before,
            &:after {
                position: absolute;
                left: 15px;
                content: ' ';
                height: 20px;
                width: 2px;
                background-color: #fff;
            }
          
            &:before {
              transform: rotate(45deg);
            }
            &:after {
              transform: rotate(-45deg);
            }
        }

        @media (min-width: 720px) {
            &.vertical {
                height: 90%;
                top: 5% !important;
                max-height: none;

                .modal-content {
                    height: 100%;
                    text-align: center;
                    background-color: #000;
                    display: flex;
                    
                    img {
                        width: auto;
                        height: 100%;
                        margin: auto auto;
                    }
                }
            }
        }
    }

    .slick-slider {
        .slick-arrow {
            position: absolute;
            top: 46%;
            z-index: 1;
            width: 20px;
            height: 30px;
            background: url(./../../media/arrow.png);
            background-size: 100% 100%;
            transform: translate(0);

            &.slick-prev {
                left: -1%;
                transform: rotate(180deg);
                &:before {
                    content: '';
                }
            }

            &.slick-next {
                right: -1%;
                &:before {
                    content: '';
                }
            }
        }

        .col {
            padding: 0;

            &.title-img-slider {
                font-family: 'GalanoGrotesqueBold';
                font-size: 20px;
                line-height: 20px;
                padding-left: 10px;
            }
        }
    }    

    @media (min-width: 720px) {
        padding-top: 30px;

        h5 {
            font-family: 'GalanoGrotesque';
        }
        
        .gallery-body {
            .gallery-info {
                .name {
                    font-size: 40px;
                    padding-right: 30px;
                    margin-bottom: 30px;
                    line-height: 40px;
                }
    
                .anio, .description {
                    font-family: 'GalanoGrotesque';
                    font-size: 20px;
                }
            }
        }

        .box-preview {
            height: 320px;
            
            .box-preview-bg {
                height: 320px;
            }
        }
    }
}